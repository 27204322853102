// pricingConfig.js
export const gbPrice = 30; // Define the price per GB

export const sliderConfig = {
  min: 10,
  max: 100,
  step: 1,
};

// Export your existing function
export const calculatePrice = (amount) => {
  if (amount > 80) {
    return amount * gbPrice * (1 - 0.40); // 40% discount
  } else if (amount > 50) {
    return amount * gbPrice * (1 - 0.30); // 30% discount
  } else if (amount > 30) {
    return amount * gbPrice * (1 - 0.20); // 20% discount
  } else {
    return amount * gbPrice; // No discount
  }
};

// Add calculateAmountFromPrice function if needed
export const calculateAmountFromPrice = (price) => {
  if (price / gbPrice > 80) {
    return price / (gbPrice * (1 - 0.40));
  } else if (price / gbPrice > 50) {
    return price / (gbPrice * (1 - 0.30));
  } else if (price / gbPrice > 30) {
    return price / (gbPrice * (1 - 0.20));
  } else {
    return price / gbPrice;
  }
};
