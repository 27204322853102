import React from 'react';

function paymentCancel() {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Платеж отменен</h1>
      <p>Вернитесь в Телеграм</p>
    </div>
  );
}

export default paymentCancel;