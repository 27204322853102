import React from 'react';

function paymentFail() {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Платеж не прошел</h1>
      <p>Вернитесь в Телеграм</p>
    </div>
  );
}

export default paymentFail;