import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function PaymentResult() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const invoiceId = params.get('invoiceId'); // Pass this as a URL parameter when redirecting to this page
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/check-payment-status?invoiceId=${invoiceId}`);
        const data = await response.json();

        if (response.ok) {
          setPaymentStatus(data.status);
        } else {
          console.error('Failed to check payment status:', data.error);
        }
      } catch (error) {
        console.error('Error:', error.message);
      } finally {
        setLoading(false);
      }
    };

    const intervalId = setInterval(checkPaymentStatus, 3000);

    return () => clearInterval(intervalId);
  }, [invoiceId]);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    
    tg.MainButton.offClick();

    const handleReloadApp = () => {
      window.location.href='/';
    };

    const handleButtonClick = () => {
      handleReloadApp();
    };

    if (!loading && (paymentStatus === 'success' || paymentStatus === 'cancel' || paymentStatus === 'overdue' || paymentStatus === 'awaiting_payment')) {
      tg.MainButton.setText("Вернуться");
      tg.MainButton.onClick(handleButtonClick);
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }

    return () => {
      tg.MainButton.offClick(handleButtonClick);
      tg.MainButton.hide();
    };
  }, [paymentStatus, loading]);

  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      {loading ? (
        <div className='text'>
          <p>Ожидание</p>
        </div>
      ) : (
        <div className='text' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
          {paymentStatus === 'created' && <p>Платеж создан</p>}
          {paymentStatus === 'overdue' && <p>Платеж просрочен</p>}
          {paymentStatus === 'awaiting_payment' && <p>Ожидание платежа...</p>}
          {paymentStatus === 'success' && <p>Успешный платеж</p>}
          {paymentStatus === 'cancel' && <p>Платеж отменен.</p>}
        </div>
      )}
    </div>
  );
}

export default PaymentResult;
