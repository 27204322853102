import React from 'react';

function paymentSuccess() {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Успех</h1>
      <p>Вернитесь в Телеграм</p>
    </div>
  );
}

export default paymentSuccess;