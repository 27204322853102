import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import { sliderConfig, calculatePrice } from './config/pricingConfig'; // Import config and functions
import './Tariffs.css';

function Tariffs() {
  const location = useLocation(); 
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userId = params.get('userId');
  console.log('userId from url param in tariffs', userId);

  const [loading, setLoading] = useState(false);
  const [trafficAmount, setTrafficAmount] = useState(sliderConfig.min); // Default value from config
  const [price, setPrice] = useState(calculatePrice(sliderConfig.min));  // Default value from config

  // Update traffic amount and price when slider is moved
  const handleTrafficChange = (e) => {
    const newTrafficAmount = parseInt(e.target.value, 10);
    setTrafficAmount(newTrafficAmount);
    setPrice(calculatePrice(newTrafficAmount));  // Calculate new price based on amount
  };

  const handlePayment = async (currentPrice, currentTrafficAmount) => {
    const invoiceId = uuidv4();
    console.log('Invoice ID:', invoiceId);
    console.log('Price:', currentPrice);
    console.log('Traffic Amount:', currentTrafficAmount);

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/initialize-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, amount: currentPrice, description: currentTrafficAmount * Math.pow(1024, 3), invoiceId }),
      });

      const data = await response.json();
      if (response.ok) {
        console.log('Payment initialized:', data);
        if (window.Telegram.WebApp) {
          window.Telegram.WebApp.openLink(data.paymentData.pay_url);
          navigate(`/payment-result?invoiceId=${invoiceId}`);
        } else {
          window.open(data.paymentData.pay_url, '_blank');
          navigate(`/payment-result?invoiceId=${invoiceId}`);
        }
      } else {
        console.error('Failed to initialize payment:', data.error);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handlePaymentClick = () => {
      handlePayment(price, trafficAmount);
    };

    tg.MainButton.setText("Получить трафик");
    tg.MainButton.show();
    tg.MainButton.onClick(handlePaymentClick);

    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      console.log("Telegram BackButton clicked");
      navigate('/');
    });

    return () => {
      tg.MainButton.offClick(handlePaymentClick).hide();
      tg.BackButton.offClick().hide();
    };
  }, [price, trafficAmount]);

  return (
    <div className='wrapper'>
      <h1 className='text' style={{ fontSize: '22px', textAlign: 'center' }}>Выберите объем трафика</h1>
      <div style={{ marginTop: '20px', marginBottom: '20px', width: '100%', textAlign: 'center' }}>
        <span className='text-button-color' style={{fontSize: '22px', fontWeight: 'bold'}}>{trafficAmount}</span><span> GB</span> за <span className='text-button-color' style={{fontSize: '22px', fontWeight: 'bold'}}>{price}</span> <span>RUB</span>
        <input
          type="range"
          min={sliderConfig.min}
          max={sliderConfig.max}
          step={sliderConfig.step}
          value={trafficAmount}
          onChange={handleTrafficChange}
          style={{ width: '100%', marginTop: '30px', marginBottom: '30px', borderRadius: '20px' }} 
          className='custom-range hint-color' 
        />
      </div>
      <div className='description'>
        После оплаты лимит трафика обновится в личном кабинете автоматически. Если этого не произошло, перезагрузите мини-приложение.<br /><br />
        Чтобы использовать ключ, скачайте приложение Streisand или FoxRay<br /><br />
        Протокол соединения - VLESS
      </div>
      {loading ? (
        <div style={{ marginTop: '20px', color: 'blue' }}>
          <p>Processing payment, please wait...</p>
        </div>
      ) : null}
    </div>
  );
}

export default Tariffs;
