import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import Tariffs from './Tariffs';
import PaymentResult from './paymentResult';
import PaymentSuccess from './paymentSuccess';
import PaymentFail from './paymentFail';
import PaymentCancel from './paymentCancel';
import InfoKey from './InfoKey';
import InfoReferral from './InfoReferral';
import { TelegramThemeProvider } from './contexts/TelegramThemeContext';
import { TelegramThemeContext } from './contexts/TelegramThemeContext';
import ThemedGlobalStyle from './GlobalStyles';
import { AnimatePresence } from 'framer-motion';
import './App.css';

const getBasePath = () => {
  return process.env.NODE_ENV === 'development' ? '/development' : '';
};

const AnimatedRoutes = () => {
  const location = useLocation();
  const basePath = getBasePath();

  return (
    <AnimatePresence mode="wait">
      <div className={`content ${location.state?.drawerOpen ? 'drawer-open' : ''}`}>
        <Routes location={location} key={location.pathname}>
          <Route path={`${basePath}/`} element={<Home />} />
          <Route path={`${basePath}/tariffs`} element={<Tariffs />} />
          <Route path={`${basePath}/payment-result`} element={<PaymentResult />} />
          <Route path={`${basePath}/payment-success`} element={<PaymentSuccess />} />
          <Route path={`${basePath}/payment-fail`} element={<PaymentFail />} />
          <Route path={`${basePath}/payment-cancel`} element={<PaymentCancel />} />
          <Route path={`${basePath}/key-info`} element={<InfoKey />} />
          <Route path={`${basePath}/referral-info`} element={<InfoReferral />} />
        </Routes>
      </div>
    </AnimatePresence>
  );
};

function App() {
  const [tgData, setTgData] = useState(null);
  const [loading, setLoading] = useState(true); // Initially true
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [errors, setErrors] = useState([]);
  const [trafficAmount, setTrafficAmount] = useState(10);
  const [price, setPrice] = useState(300);
  const theme = useContext(TelegramThemeContext);
  const [marzbanUser, setMarzbanUser] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const [userData, setUserData] = useState(null);

  const userAgent = navigator.userAgent.toLowerCase();

  const iphoneLink = 'https://apps.apple.com/app/id6450534064';
  const androidLink = 'https://play.google.com/store/apps/details?id=com.v2ray.ang&pcampaignid=web_share';
  const macLink = 'https://apps.apple.com/ru/app/foxray/id6448898396';
  
  function getDeviceInfo(userAgent) {
      if (/iphone|ipad|ipod/i.test(userAgent)) {
          return { 
            deviceType: "iOS",
            link: iphoneLink,
            images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] };
      } else if (/android/i.test(userAgent)) {
          return {
            deviceType: "Android",
            link: androidLink,
            images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] };
      } else if (/windows phone/i.test(userAgent)) {
          return {
            deviceType: "Windows Phone",
            link: null,
            images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] };
      } else if (/windows/i.test(userAgent)) {
          return {
            deviceType: "Windows PC",
            link: null,
            images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] };
      } else if (/macintosh|mac os x/i.test(userAgent)) {
          return {
            deviceType: "Mac",
            link: macLink,
            images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] };
      } else if (/linux/i.test(userAgent)) {
          return {
            deviceType: "Linux",
            link: null,
            images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] };
      } else {
          return {
            deviceType: "Unknown device",
            link: null,
            images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] };
      }
  }

// Get the device type from user agent
const deviceInfo = getDeviceInfo(userAgent);
console.log("Device Info: ", deviceInfo);

// Optionally, save the device type to sessionStorage
sessionStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));

  const verifyTelegramData = async (initData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/verify-telegram-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ initData }),
      });

      const result = await response.json();
      if (response.ok) {
        sessionStorage.setItem('userData', JSON.stringify(result.userData));
        setUserData(result.userData);
        return result.userData.id;
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      setErrors((prevErrors) => [...prevErrors, error.message]);
      setVerificationFailed(true);
      throw error;
    }
  };

  const fetchMarzbanUser = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-marzban-user?userId=${userId}`);
      const data = await response.json();

      if (response.ok) {
        setMarzbanUser(data);
        sessionStorage.setItem('marzbanUserData', JSON.stringify(data));
      } else {
        console.log('No Marzban user found, creating one...');
        //const limit = 5;
        await handleCreateMarzbanUser(userId);
      }
    } catch (error) {
      console.error('Error fetching Marzban user:', error);
      setErrors((prevErrors) => [...prevErrors, error.message]);
    }
  };

  const handleCreateMarzbanUser = async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      const data = await response.json();

      if (response.ok) {
        setResponseMessage(`User created successfully: ${JSON.stringify(data)}`);
        setMarzbanUser(data);
        sessionStorage.setItem('marzbanUserData', JSON.stringify(data));
      } else {
        setResponseMessage(`Failed to create user: ${data.error}`);
      }
    } catch (error) {
      setResponseMessage(`Error: ${error.message}`);
    }
  };

  const calculatePrice = (amount) => {
    if (amount > 80) {
      return amount * 15;
    } else if (amount > 50) {
      return amount * 20;
    } else if (amount > 30) {
      return amount * 25;
    } else {
      return amount * 30;
    }
  };

  const handleTrafficChange = (e) => {
    const newTrafficAmount = parseInt(e.target.value, 10);
    setTrafficAmount(newTrafficAmount);
    setPrice(calculatePrice(newTrafficAmount));
  };

  const handleBackToBot = () => {
    window.location.href = `https://t.me/${process.env.REACT_APP_BOT_NAME}`;
  };

  useEffect(() => {
    console.log('backend url', process.env.REACT_APP_BACKEND_URL);
    const tg = window.Telegram.WebApp;

    if (tg && tg.initData) {
      tg.ready();
      tg.expand();
      tg.disableVerticalSwipes();

      const fetchData = async () => {
        try {
          const userId = await verifyTelegramData(tg.initData);
          if (userId) {
            await fetchMarzbanUser(userId);
          }
        } catch (error) {
          console.log('Error fetching Marzban user in App.js:', error);
          setVerificationFailed(true);
        } finally {
          setLoading(false);  // Only set loading to false after all async calls are done
        }
      };

      fetchData();
    } else {
      console.log("initData is missing");
      setLoading(false);
      setVerificationFailed(true);
      alert("Please open this app through Telegram for the best experience.");
    }
  }, []);

  if (loading) {
    return (
      <div className='text' style={{display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
        <p>Загрузка...</p>
      </div>
    );
  }

  if (verificationFailed) {
    return (
      <div className="wrapper">
        <h1>PVT key</h1>
        <div style={{ marginBottom: '20px' }}>
          <p>Selected Traffic: {trafficAmount} GB</p>
          <p>Price: {price} RUB</p>
          <input
            type="range"
            min="10"
            max="100"
            step="1"
            value={trafficAmount}
            onChange={handleTrafficChange}
            style={{ width: '300px' }}
          />
        </div>
        <button
          onClick={handleBackToBot}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#6c757d',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Back to Bot
        </button>
      </div>
    );
  }

  return (
    <TelegramThemeProvider>
      <ThemedGlobalStyle />
      <Router>
        <AnimatedRoutes />
      </Router>
    </TelegramThemeProvider>
  );
}

export default App;
