import { createGlobalStyle } from 'styled-components';
import { useContext } from 'react';
import { TelegramThemeContext } from './contexts/TelegramThemeContext';

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.bg_color};
    color: ${({ theme }) => theme.text_color};
  }

  .bg-color {
    background-color: ${({ theme }) => theme.bg_color};
  }

  .secondary-bg-color: ${({ theme }) => theme.secondary_bg_color};

    h1 {
        color: ${({ theme }) => theme.text_color};
    }

    h2 {
        color: ${({ theme }) => theme.text_color};
    }

    .under-header {
        color: ${({ theme }) => theme.text_color};
    }

    .hint {
        color: ${({ theme }) => theme.hint_color};
    }

    .hint-color {
      background-color: ${({ theme }) => theme.hint_color};
    }

    .text {
        color: ${({ theme }) => theme.text_color} !important;
    }

    .action-button {
    background-color: ${({ theme }) => theme.button_color};
    color: ${({ theme }) => theme.button_text_color};
    border-radius: 10px;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    margin-bottom: 6px;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
  }

    .cancel-button {
      background: none;
      color: ${({ theme }) => theme.text_color};
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
    }

  .background-button-color {
    background-color: ${({ theme }) => theme.button_color};
  }

  .text-button-color {
    color: ${({ theme }) => theme.button_color};
  }

  a {
    color: ${({ theme }) => theme.link_color};
  }

  .console-messages {
    color: ${({ theme }) => theme.text_color};
  }

  .icon-button {
    color: ${({ theme }) => theme.button_color};
    border: 2px solid ${({ theme }) => theme.button_color};
    border-radius: 20px;
    background: none;
    font-size: 14px;
    cursor: pointer;
    margin-top: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal-content {
    background-color:${({ theme }) => theme.secondary_bg_color};
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
  }
`;

const ThemedGlobalStyle = () => {
  const theme = useContext(TelegramThemeContext);
  return <GlobalStyles theme={theme} />;
};

export default ThemedGlobalStyle;
