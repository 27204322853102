import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TelegramThemeContext } from './contexts/TelegramThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import './Home.css';

function Home() {
  const [profilePictureUrl, setProfilePictureUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [statusColor, setStatusColor] = useState('🟢');
  const [statusText, setStatusText] = useState('Активный');
  const theme = useContext(TelegramThemeContext);
  const navigate = useNavigate();

  const rawMarzbanUserData = sessionStorage.getItem('marzbanUserData');
  const marzbanUserData = JSON.parse(rawMarzbanUserData);

  const storedUserData = sessionStorage.getItem('userData');
  const userData = JSON.parse(storedUserData);

  console.log('marzbanUserData', marzbanUserData);
  console.log('userData', userData);

  function interpolateColor(percent, startColor, endColor) {
    console.log('start-end color', startColor, endColor);
    const start = {
      r: parseInt(startColor.slice(1, 3), 16),
      g: parseInt(startColor.slice(3, 5), 16),
      b: parseInt(startColor.slice(5, 7), 16)
    };
    const end = {
      r: parseInt(endColor.slice(1, 3), 16),
      g: parseInt(endColor.slice(3, 5), 16),
      b: parseInt(endColor.slice(5, 7), 16)
    };
  
    const r = Math.round(start.r + (end.r - start.r) * percent);
    const g = Math.round(start.g + (end.g - start.g) * percent);
    const b = Math.round(start.b + (end.b - start.b) * percent);
  
    return `rgb(${r}, ${g}, ${b})`;
  }

  const calculatePercentage = () => {
    return marzbanUserData.data_limit > 0 ? marzbanUserData.used_traffic / marzbanUserData.data_limit : 0;
  };  

  const updateStatusBarColor = () => {
    const usagePercentage = calculatePercentage(); // Between 0 and 1
    const newColor = interpolateColor(usagePercentage, '#00FF00', '#FF0000'); // From green to red
    return newColor;
  };

  useEffect(() => {
    if (marzbanUserData) {
      updateStatusBarColor();
    }
  }, [marzbanUserData]);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (!userData) return;

      try {
        const response = await fetch(`https://api.telegram.org/bot${process.env.REACT_APP_BOT_TOKEN}/getUserProfilePhotos?user_id=${userData.tgid}`);
        const data = await response.json();

        if (data.ok && data.result.total_count > 0) {
          const fileId = data.result.photos[0][0].file_id;
          const fileResponse = await fetch(`https://api.telegram.org/bot${process.env.REACT_APP_BOT_TOKEN}/getFile?file_id=${fileId}`);
          const fileData = await fileResponse.json();
          if (fileData.ok) {
            const filePath = fileData.result.file_path;
            const url = `https://api.telegram.org/file/bot${process.env.REACT_APP_BOT_TOKEN}/${filePath}` || 'public/logo.jpg';
            setProfilePictureUrl(url);
          }
        }
      } catch (error) {
        console.error('Error fetching profile photos:', error);
      }
    };

    fetchProfilePicture();
  }, [userData]);

  useEffect(() => {
    if (marzbanUserData.used_traffic > 0.99 * marzbanUserData.data_limit) {
      setStatusColor('🔴');
      setStatusText('Пополните баланс');
    } else if (marzbanUserData.used_traffic > 0.9 * marzbanUserData.data_limit) {
      setStatusColor('🟠');
      setStatusText('Активный');
    } else {
      setStatusColor('🟢');
      setStatusText('Активный');
    }
  }, [marzbanUserData]);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handleNavigateTariffs = () => {
      if (userData?.id) {
        navigate(`/tariffs?userId=${userData.id}`);
      } else {
        setResponseMessage('User ID is missing.');
      }
    };

    tg.MainButton.setText("Получить еще трафик")
      .show()
      .onClick(handleNavigateTariffs);
    
    return () => {
      tg.MainButton.offClick(handleNavigateTariffs).hide();
    };
  }, [userData]);

  const handleCopyKey = () => {
    if (navigator.clipboard && marzbanUserData?.links[0]) {
      navigator.clipboard.writeText(marzbanUserData.links[0])
        .then(() => {
          handleButtonTextChange();
        })
        .catch(err => {
          console.error('Could not copy text:', err);
          // Fallback to execCommand
          const textArea = document.createElement("textarea");
          textArea.value = marzbanUserData.links[0];
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            document.execCommand('copy');
            handleButtonTextChange();
          } catch (err) {
            console.error('Fallback: Could not copy text:', err);
          }
          document.body.removeChild(textArea);
        });
    } else {
      alert('Clipboard API not available or link is missing');
    }
  };
  
  const handleButtonTextChange = () => {
    const copyButton = document.querySelector('.copy-button-text');
    copyButton.textContent = 'Ключ скопирован';
    setTimeout(() => {
      copyButton.textContent = 'Скопировать ключ';
    }, 2000);
  };

  const handleShareClick = () => {
    const shareButton = document.querySelector('.share-button');
    const referralLink = `https://t.me/${process.env.REACT_APP_BOT_NAME}/${process.env.REACT_APP_APP_NAME}?startapp=${userData.tgid}`;
  
    if (navigator.share) {
      navigator.share({
        title: 'Поделиться ботом PVT Key',
        url: referralLink,
      }).then(() => {
        shareButton.textContent = 'Ссылка скопирована';
        setTimeout(() => {
          shareButton.textContent = 'Поделиться ботом';
        }, 2000);
      }).catch((error) => console.error('Error sharing:', error));
    } else {
        const textArea = document.createElement('textarea');
        textArea.value = referralLink;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          shareButton.textContent = 'Скопировано';
          setTimeout(() => {
            shareButton.textContent = 'Поделиться ботом';
          }, 2000);
        } catch (err) {
          console.error('Fallback: Could not copy text:', err);
        }
        document.body.removeChild(textArea);
    }
  };

  const handleNavigateToBot = () => {
    window.Telegram.WebApp.openLink(`https://t.me/${process.env.REACT_APP_BOT_NAME}`);
  };
  
  const handleNavigateKeyInfo = () => {
    navigate('/key-info');
  };

  const handleNavigateReferralInfo = () => {
    navigate('/referral-info');
  };

  return (
    <div>
      {loading ? (
        <div className='text' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
          <p>Загрузка...</p>
        </div>
      ) : (
      <div className='wrapper'>
        <div className='logo-wrapper'>
          {profilePictureUrl ? (
            <img className='logo' src={profilePictureUrl} alt='Telegram User Pic' />
          ) : (
            <img className='logo' src='/logo.jpg' alt='Default User Pic' />
          )}
          <div>
            <div className='name text'>{userData.firstname || userData.username || 'Пользователь'}</div>
            <div className='tg-id text'>ID: {userData.tgid}</div>

            {userData.bot_subscription == false && (
              <button style= {{
                border: 'none',
                background: 'none',
                margin: '0px 0px'
              }}
                onClick={(handleNavigateToBot)}
              >
                <div style={{textAlign: 'left'}}>❗️ <span className='text' style={{textDecoration: 'underline'}}>Подпишитесь на бот, чтобы не потерять</span></div>
              </button>
            )}
          </div>
        </div>

        {marzbanUserData && (
          <div>
            <h2 className='text'>Ключ</h2>
            <div className='section text secondary-bg-color' style={{background: theme.secondary_bg_color }}>
              <div className='horizontal-flex'>
                <div>
                  <div className='status'>
                    <span>{statusColor}</span> 
                    <span style={{ color: statusColor === '🔴' ? 'red' : statusColor === '🟠' ? 'orange' : 'rgb(20,160,5)' }}>{statusText}</span>
                  </div>
                  <div className="status-bar-container" style={{borderRadius: '10px'}}>
                    <div
                      className="status-bar"
                      style={{
                        width: `${calculatePercentage() * 100}%`,
                        backgroundColor: updateStatusBarColor(),
                        borderRadius: '10px',
                        height: '20px',
                      }}
                    ></div>
                  </div>
                  <div className="stats text">
                    Использовано: <span>{(marzbanUserData.used_traffic / Math.pow(1024, 3)).toFixed(2)} ГБ / {(marzbanUserData.data_limit / Math.pow(1024, 3)).toFixed(2)} ГБ</span>
                  </div>
                </div>
                <button className='icon-button' onClick={handleNavigateKeyInfo}>
                  <FontAwesomeIcon icon={faQuestion} />
                </button>
              </div>
                <button className='action-button' onClick={handleCopyKey}>
                  <div className='copy-button-text'>Скопировать ключ</div>
                </button>
            </div>
          </div>
        )}

        <div>
          <h2 className='text'>Реферальная программа</h2>
          <div className='referral-description text'>Поделитесь своей индивидуальной реферальной ссылкой и получайте кэшбек 20% за все платежи ваших рефералов пожизненно.</div>
          <div className='section text secondary-bg-color' style={{background: theme.secondary_bg_color }}>
            <div className='horizontal-flex'>
              <div>
                <div className="stats text" style={{marginTop: '0px'}}>Рефералов: {userData.referral_count}</div>
                <div className="stats text">Заработано: {userData.referral_balance}</div>
              </div>
              <button className='icon-button' onClick={handleNavigateReferralInfo}>
                <FontAwesomeIcon icon={faQuestion} />
              </button>
            </div>
            <button className='action-button' onClick={handleShareClick}>
            <div className='share-button'>Поделиться ботом</div>
            </button>
          </div>
        </div>

        {responseMessage && (
          <div style={{ marginTop: '20px', color: 'green' }}>
            <p>{responseMessage}</p>
          </div>
        )}
      </div>
      )}
    </div>
  );
}

export default Home;
